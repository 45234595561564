import { Navigate } from 'react-router-dom';
import paths from './paths';
import { useAuth, useTutor } from 'store/auth/roles';
import { useAppDispatch } from 'store/hooks';
import { LOGGIN } from 'store/actions/types';

const TutorRoute = ({ elementCustom }) => {
	let isAuthenticated = useAuth();
	let isTutor = useTutor();
	const dispatch = useAppDispatch();

	if (localStorage.__emergeLMSDashUser__ && !isTutor) {
		const user = JSON.parse(localStorage.__emergeLMSDashUser__);
		isTutor = user.role === 'tutor';
		dispatch({
			type: LOGGIN,
			payload: user
		});
	}
	return isAuthenticated && isTutor ? (
		<>{elementCustom}</>
	) : (
		<Navigate to={paths.landing} />
	);
};

export default TutorRoute;
