import {
	Route,
	Navigate,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements
} from 'react-router-dom';

import 'assets/scss/theme.scss';

import DashboardIndex from 'layouts/dashboard/DashboardIndex';
import AuthLayout from 'layouts/dashboard/AuthLayout';

import packageInfo from '../../package.json';
import CacheBuster from 'react-cache-buster';
import NotFound from 'layouts/marketing/NotFound';

// Components
import React, { lazy, Suspense, Fragment } from 'react';

import Error404 from 'components/marketing/pages/specialty/Error404';

import paths from './paths';
import UnAuthRoute from './UnprotectedRoutes';
import AdminRoute from './AdminRoute';
import TutorRoute from './TutorRoutes';

import SignIn from 'components/dashboard/authentication/SignIn';
import SignUp from 'components/dashboard/authentication/SignUp';
import ForgetPassword from 'components/dashboard/authentication/ForgetPassword';
import FullSpinner from 'components/FullSpinner';
import ScrollToTop from 'layouts/dashboard/ScrollToTop';

// Lazy loaded components
const Overview = lazy(() => import('components/dashboard/overview/Overview'));
const AllCourses = lazy(() =>
	import('components/dashboard/courses/all-courses/AllCourses')
);
const Instructor = lazy(() => import('components/dashboard/user/Instructor'));
const Students = lazy(() => import('components/dashboard/user/Students'));
const Notifications = lazy(() =>
	import('components/dashboard/authentication/Notifications')
);
const EditProfile = lazy(() =>
	import('components/marketing/account-settings/EditProfile')
);

const Security = lazy(() =>
	import('components/marketing/account-settings/Security')
);
const Moderator = lazy(() => import('components/dashboard/user/Moderator'));
const EditContent = lazy(() =>
	import('components/marketing/pages/courses/add-new-course/EditContent')
);
const Submissions = lazy(() =>
	import('components/marketing/instructor/Submission')
);
const ChatLayout = lazy(() => import('layouts/dashboard/ChatLayout'));
const Chat = lazy(() => import('components/dashboard/chat/Chat'));
const AdminSignUp = lazy(() =>
	import('components/dashboard/authentication/admin/AdminSignUp')
);
const ResetPassword = lazy(() =>
	import('components/dashboard/authentication/ResetPassword')
);
const InstructorQuizSingle = lazy(() =>
	import('components/marketing/instructor/QuizSingle')
);
const InstructorDashboard = lazy(() =>
	import('components/marketing/instructor/Dashboard')
);
const InstructorReviews = lazy(() =>
	import('components/marketing/instructor/Reviews')
);
const Earnings = lazy(() => import('components/marketing/instructor/Earnings'));
const InstructorStudents = lazy(() =>
	import('components/marketing/instructor/Students')
);
const ViewProfile = lazy(() =>
	import('components/marketing/instructor/ViewProfile')
);

const CourseSingle = lazy(() =>
	import('components/marketing/pages/courses/course-single/CourseSingle')
);
const AddNewCourse = lazy(() =>
	import('components/marketing/pages/courses/add-new-course/AddNewCourse')
);
const AddNewContent = lazy(() =>
	import('components/marketing/pages/courses/add-new-course/AddNewContent')
);

const router = createBrowserRouter(
	createRoutesFromElements(
		<Fragment>
			{/* Routes with AuthLayout */}
			<Route element={<AuthLayout />}>
				<Route
					path={paths.landing}
					element={<UnAuthRoute elementCustom={<SignIn />} />}
				/>
				<Route path="/authentication/sign-up" element={<SignUp />} />
				<Route
					path="/authentication/forget-password"
					element={<ForgetPassword />}
				/>
				<Route
					path="/authentication/reset-password/:token"
					element={<ResetPassword />}
				/>
			</Route>

			<Route
				path={paths.tutordash}
				element={<TutorRoute elementCustom={<InstructorDashboard />} />}
			/>

			<Route
				path="/instructor/add-new-course/"
				element={<TutorRoute elementCustom={<AddNewCourse />} />}
			/>

			<Route
				path="/instructor/add-new-content/:id"
				element={<TutorRoute elementCustom={<AddNewContent />} />}
			/>
			<Route
				path="/instructor/edit-content/:contentId"
				element={<TutorRoute elementCustom={<EditContent />} />}
			/>
			<Route
				path="/instructor/course-single/:courseContentId/:courseId"
				element={<TutorRoute elementCustom={<CourseSingle />} />}
			/>
			<Route
				path="/instructor/course-single/:courseContentId/:courseId/:tabName"
				element={<TutorRoute elementCustom={<CourseSingle />} />}
			/>

			{/* <Route
				path="/instructor/instructor-my-courses/"
				element={<TutorRoute elementCustom={<InstructorMyCourses />} />}
			/> */}
			<Route
				path="/instructor/instructor-reviews/"
				element={<TutorRoute elementCustom={<InstructorReviews />} />}
			/>
			<Route
				path="/instructor/instructor-earnings/"
				element={<TutorRoute elementCustom={<Earnings />} />}
			/>

			<Route
				path="/instructor/enrolled-students/"
				element={<TutorRoute elementCustom={<InstructorStudents />} />}
			/>

			<Route
				path="/instructor/instructor-edit-profile/"
				element={<TutorRoute elementCustom={<EditProfile />} />}
			/>

			<Route
				path="/instructor/instructor-profile/"
				element={<TutorRoute elementCustom={<ViewProfile />} />}
			/>
			<Route
				path="/instructor/instructor-security/"
				element={<TutorRoute elementCustom={<Security />} />}
			/>

			<Route
				path="/instructor/quiz/:sectionId"
				element={<TutorRoute elementCustom={<InstructorQuizSingle />} />}
			/>

			<Route
				path="/assignment/group/:assignmentData"
				element={<TutorRoute elementCustom={<Submissions />} />}
			/>

			<Route
				path="/assignment/individual/:assignmentData"
				element={<TutorRoute elementCustom={<Submissions />} />}
			/>

			<Route element={<ChatLayout />}>
				<Route
					path="/dashboard/chat"
					element={<TutorRoute elementCustom={<Chat />} />}
				/>
			</Route>

			{/* Routes with NotFound */}
			<Route element={<NotFound />}>
				<Route path="/marketing/specialty/404-error/" element={<Error404 />} />
			</Route>

			{/* Routes (DASHBOARD ROUTERS) with DashboardIndex */}
			<Route element={<DashboardIndex />}>
				<Route
					path={paths.admindash}
					element={<AdminRoute elementCustom={<Overview />} />}
				/>
				<Route
					path={paths.adminsignup}
					element={<AdminRoute elementCustom={<AdminSignUp />} />}
				/>
				{/* <Route
					path={paths.analytics}
					element={<AdminRoute elementCustom={<Analytics />} />}
				/> */}
				<Route
					path={paths.allcourses}
					element={<AdminRoute elementCustom={<AllCourses />} />}
				/>
				<Route
					path="/user/instructor"
					element={<AdminRoute elementCustom={<Instructor />} />}
				/>
				<Route
					path="/user/students"
					element={<AdminRoute elementCustom={<Students />} />}
				/>
				<Route path="/user/moderator" element={<Moderator />} />
				<Route
					path="/authentication/notifications"
					element={<Notifications />}
				/>
			</Route>

			{/*Redirect*/}
			<Route
				path="*"
				element={<Navigate to="/marketing/specialty/404-error/" replace />}
			/>
		</Fragment>
	)
);
function AllRoutes() {
	const isProduction = process.env.NODE_ENV === 'production';

	return (
		<CacheBuster
			currentVersion={packageInfo.version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			loadingComponent={<p></p>} //If not pass, nothing appears at the time of new version check.
			metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
		>
			<Suspense fallback={<FullSpinner />}>
				<RouterProvider router={router}>
					<ScrollToTop />
				</RouterProvider>
			</Suspense>
		</CacheBuster>
	);
}

export default AllRoutes;
