export const settings = {
	app: {
		name: 'Daruur LMS React',
		version: '2.2.0'
	},
	theme: {
		skin: 'light' // possible values are light, dark
	}
};
export default { settings };
