import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import { Provider } from 'react-redux';
import AppProvider from './context/providers/AppProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import jwtDecode from 'jwt-decode';
import setAuthToken from 'services/setAuthToken';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pdfjs } from 'react-pdf';

const container = document.getElementById('root');
// const queryClient = new QueryClient();
const queryClient = new QueryClient({
	// defaultOptions: {
	// 	queries: {
	// 		refetchOnMount: false,
	// 		refetchOnWindowFocus: false,
	// 		retry: 1
	// 	}
	// }
});
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.js',
	import.meta.url
).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
if (localStorage.__emergeLMSDash__) {
	let token = localStorage.__emergeLMSDash__;
	let decoded = jwtDecode(token);
	let timeNow = Date.now() / 1000;

	// check if token is expired
	if (decoded.exp < timeNow) {
		store.dispatch({
			type: 'LOGOUT',
			payload: null
		});
		localStorage.removeItem('__emergeLMSDash__');
		window.location.href = '/';
		window.location.reload();
	}

	const session = decoded;
	store.dispatch({
		type: 'LOGIN',
		payload: session
	});

	// set headers
	setAuthToken(token);
}
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<AppProvider>
					<App />
					<ToastContainer />
				</AppProvider>
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
