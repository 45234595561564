import { Navigate } from 'react-router-dom';
import paths from './paths';
import { useAdmin, useAuth, useTutor } from 'store/auth/roles';

const UnAuthRoute = ({ elementCustom }) => {
	const isAuthenticated = useAuth();
	const isTutor = useTutor();
	const isAdmin = useAdmin();
	return !isAuthenticated ? (
		<>{elementCustom}</>
	) : isTutor ? (
		<Navigate to={paths.tutordash} />
	) : isAdmin ? (
		<Navigate to={paths.admindash} />
	) : (
		<Navigate to={paths.landing} />
	);
};

export default UnAuthRoute;
