import AllRoutes from 'routes/AllRoutes';

import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';

function App() {
	return <AllRoutes />;
}

export default App;
