import { Navigate } from 'react-router-dom';
import paths from './paths';
import { useAdmin, useAuth } from 'store/auth/roles';
import { useAppDispatch } from 'store/hooks';
import { LOGGIN } from 'store/actions/types';

const AdminRoute = ({ elementCustom }) => {
	let isAuthenticated = useAuth();
	let isAdmin = useAdmin();
	const dispatch = useAppDispatch();

	if (localStorage.__emergeLMSDashUser__ && !isAdmin) {
		const user = JSON.parse(localStorage.__emergeLMSDashUser__);
		isAdmin = user.role === 'admin';
		dispatch({
			type: LOGGIN,
			payload: user
		});
	}

	return isAuthenticated && isAdmin ? (
		<>{elementCustom}</>
	) : (
		<Navigate to={paths.unauthorized} />
	);
};

export default AdminRoute;
