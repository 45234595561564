const paths = {
	landing: '/',
	login: '/login',
	admindash: '/dashboard/admin/',
	tutordash: '/dashboard/tutor/',
	forgotpassword: '/forgot-password/',
	resetpassword: '/users/reset/:token',
	unathorized: '/401/',
	register: '/register/',
	adminsignup: '/dashboard/admin/sign-up',
	analytics: '/dashboard/admin/analytics',
	allcourses: '/courses/all-courses'
};

export default paths;
