// import node module libraries
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
	Col,
	Row,
	Card,
	Form,
	Button,
	Image,
	InputGroup,
	Alert
} from 'react-bootstrap';

// import media files
import Logo from 'assets/images/brand/logo/logo-icon.png';
import Spinner from '../../FullSpinner';
import { login } from 'data/mutations/auth';
import { useMutation } from 'react-query';
import paths from 'routes/paths';
const SignIn = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [visiblePassword, setVisiblePassword] = useState(false);
	const [error, setError] = useState('');
	const { mutate: logIn, isLoading } = useMutation(
		() => login({ email, password }, dispatch),
		{
			onError: (error) => {
				setTimeout(() => {
					setError('');
				}, 5000);
				setError(error.message);
			}
		}
	);

	// useEffect(() => {
	// 	console.log(localStorage.__emergeLMSDashUser__);
	// 	if (localStorage.__emergeLMSDashUser__) {
	// 		const user = JSON.parse(localStorage.__emergeLMSDashUser__);
	// 		const isATutor = user.role === 'tutor';
	// 		navigate(isATutor ? paths.tutordash : paths.admindash);
	// 	}
	// }, [navigate]);

	const handleLogin = async (e) => {
		e.preventDefault();
		logIn();
	};

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image
										src={Logo}
										className="mb-4 align-items-center"
										alt=""
										style={{ width: '200px', height: 'auto' }}
									/>
								</Link>
								<div className="mb-2">
									{error !== '' && <Alert variant="danger">{error}</Alert>}
								</div>
								<h1 className="mb-1 fw-bold">Sign in</h1>
								{/* <span>
									Don’t have an account?{' '}
									<Link to="/authentication/sign-up" className="ms-1">
										Sign up
									</Link>
								</span> */}
							</div>
							{/* Form */}
							<Form onSubmit={handleLogin}>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* Username or email */}
										<Form.Label>Email </Form.Label>
										<Form.Control
											type="email"
											id="email"
											placeholder="Email address here"
											required
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Password */}
										<Form.Label>Password </Form.Label>
										<InputGroup>
											<Form.Control
												id="password"
												placeholder="**************"
												required
												type={visiblePassword ? 'text' : 'password'}
												value={password}
												onChange={(e) => setPassword(e.target.value)}
											/>
											<InputGroup.Text>
												<span
													className="material-icons"
													onClick={() => setVisiblePassword(!visiblePassword)}
												>
													{visiblePassword ? 'visibility_off' : 'visibility'}
												</span>
											</InputGroup.Text>
										</InputGroup>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Checkbox */}
										<div className="d-md-flex justify-content-between align-items-center">
											<Form.Group
												className="mb-3 mb-md-0"
												controlId="formBasicCheckbox"
											>
												<Form.Check type="checkbox" label="Remember me" />
											</Form.Group>
											<Link to="/authentication/forget-password">
												Forgot your password?
											</Link>
										</div>
									</Col>
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										<Button variant="primary" type="submit">
											Sign in
										</Button>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignIn;
