// import node module libraries
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Row, Col, Image, Dropdown, ListGroup } from 'react-bootstrap';

// import { logout, reset } from '../store/auth/authSlice';
// simple bar scrolling used for notification item scrolling
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';
import DarkLightMode from 'layouts/DarkLightMode';
import GKTippy from 'components/elements/tooltips/GKTippy';

// import media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import Spinner from 'components/FullSpinner';

// import data files
import { logout } from 'data/mutations/auth';
import { useAppDispatch } from 'store/hooks';
import { Windows } from 'react-bootstrap-icons';

const QuickMenu = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { session, isLoading, isError, isSuccess, message } = useSelector(
		(state) => state.auth
	);
	const isDesktop = useMediaQuery({
		query: '(min-width: 1224px)'
	});

	const [show, setShow] = useState(false);

	useEffect(() => {
		if (isError) {
			toast.error(message);
		}
	}, [session, isError, isSuccess, message, navigate, dispatch]);
	const handleLogout = async (e) => {
		e.preventDefault();
		setShow(true);
		await logout(dispatch);
		navigate('/');
		window.location.reload();
		setShow(false);
	};
	if (show) {
		return <Spinner />;
	}
	return (
		<Fragment>
			<DarkLightMode />
			<ListGroup
				as="ul"
				bsPrefix="navbar-nav"
				className="navbar-right-wrap ms-2 d-flex nav-top-wrap"
			>
				<Dropdown as="li">
					{/* <Dropdown.Toggle
						as="a"
						bsPrefix=" "
						className="text-dark icon-notifications me-lg-1  btn btn-light btn-icon rounded-circle indicator indicator-primary text-muted"
						id="dropdownNotification"
					>
						<i className="fe fe-bell"></i>
					</Dropdown.Toggle> */}
					<Dropdown.Menu
						show={isDesktop ? true : false}
						className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end mt-3 py-0"
						aria-labelledby="dropdownNotification"
						align="end"
					>
						{/* <div className="border-bottom px-3 pt-3 pb-3 d-flex justify-content-between align-items-end">
							<span className="h4 mb-0">Notifications</span>
							<Link to="# " className="text-muted">
								<span className="align-middle">
									<i className="fe fe-settings me-1"></i>
								</span>
							</Link>
						</div> */}
						{/* <Notifications /> */}
						{/* <div className="border-top px-3 pt-3 pb-3">
							<Link
								to="/authentication/notifications"
								className="text-link fw-semi-bold"
							>
								See all Notifications
							</Link>
						</div> */}
					</Dropdown.Menu>
				</Dropdown>

				<Dropdown as="li" className="ms-1">
					<Dropdown.Toggle
						as="a"
						bsPrefix=" "
						className="rounded-circle"
						id="dropdownUser"
					>
						<div className="avatar avatar-md avatar-indicators avatar-online">
							{/* <Image alt="avatar" src={Avatar1} className="rounded-circle" /> */}
							<h3
								className="rounded-circle"
								style={{ backgroundColorolor: 'purple' }}
							>
								ADM
							</h3>
						</div>
					</Dropdown.Toggle>
					<Dropdown.Menu
						show={isDesktop ? true : false}
						className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
						aria-labelledby="dropdownUser"
						align="end"
					>
						<Dropdown.Item className="mt-3">
							<div className="d-flex">
								<div className="avatar avatar-md avatar-indicators avatar-online">
									{/* <Image
										alt="avatar"
										src={Avatar1}
										className="rounded-circle"
									/> */}
								</div>
								<div className="ms-3 lh-1">
									{session && session.email ? (
										<>
											<h5 className="mb-1">{session.email}</h5>
											<p className="mb-0 text-muted">{session.role}</p>
										</>
									) : (
										<p>Loading user data...</p>
									)}
								</div>
							</div>
						</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item eventKey="2">
							<i className="fe fe-user me-2"></i> Profile
						</Dropdown.Item>
						{/* <Dropdown.Item eventKey="3">
							<i className="fe fe-star me-2"></i> Subscription
						</Dropdown.Item> */}
						{/* <Dropdown.Item>
							<i className="fe fe-settings me-2"></i> Settings
						</Dropdown.Item> */}
						<Dropdown.Divider />
						<Dropdown.Item className="mb-3" onClick={handleLogout}>
							<i className="fe fe-power me-2"></i> Sign Out
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</ListGroup>
		</Fragment>
	);
};

export default QuickMenu;
