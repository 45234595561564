// import node module libraries
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Fragment } from 'react';

import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';

// import media files
import Logo from 'assets/images/brand/logo/logo-icon.png';
import { useMutation } from 'react-query';
import { forgotPassword } from 'data/mutations/auth';
import Spinner from 'components/FullSpinner';
import paths from 'routes/paths';

const ForgetPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [success, setSuccess] = useState('');

	const { mutate: forgotPass, isLoading } = useMutation(forgotPassword, {
		onSuccess() {
			setTimeout(() => {
				setSuccess('');
			}, 5000);
			setSuccess('Request successful! Please check your email');
		}
	});

	const handleForgotPassword = async (e) => {
		e.preventDefault();

		const formData = {
			email: email
		};

		forgotPass(formData);
		navigate('/');
	};
	if (isLoading) {
		return <Spinner />;
	}
	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image
										src={Logo}
										className="mb-4"
										alt=""
										style={{ width: '200px', height: 'auto' }}
									/>
								</Link>
								<h1 className="mb-1 fw-bold">Forgot Password</h1>
								<span>Fill the form to reset your password.</span>
							</div>
							{/* Form */}
							<Form onSubmit={handleForgotPassword}>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/*  email */}
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											id="email"
											placeholder="Enter your email"
											required
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3 d-grid gap-2">
										{/* Button */}
										<Button variant="primary" type="submit">
											Send Reset Link
										</Button>
									</Col>
								</Row>
								<span>
									Return to <Link to="/authentication/sign-in">Sign in</Link>
								</span>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default ForgetPassword;
