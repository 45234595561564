import { ROLES } from 'helper/enums/roles.enum';
import { LOGGIN, LOGOUT } from '../actions/types';

const initState = {
	session: null,
	isAuthenticated: false,
	isTutor: false,
	isAdmin: false,
	isStudent: false
};

const auth = (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case LOGGIN:
			return {
				...state,
				session: payload,
				isAuthenticated: true,
				isTutor: payload.isAdmin === ROLES.TUTOR,
				isAdmin: payload.isAdmin === ROLES.ADMIN
			};
		case LOGOUT:
			return {
				...state,
				session: null,
				isAuthenticated: false,
				isTutor: false,
				isAdmin: false
			};

		default:
			return state;
	}
};

export default auth;
